
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$custom-typography: mat.define-typography-config(
  $font-family: 'Montserrat'
);

// Now we have sweet buttons with Comic Sans.
@include mat.typography-hierarchy($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$mat-colors-primary: (
  50 : #e9f0f6,
  100 : #c9dae9,
  200 : #a5c2da,
  300 : #80aacb,
  400 : #6597c0,
  500 : #4a85b5,
  600 : #437dae,
  700 : #3a72a5,
  800 : #32689d,
  900 : #22558d,
  A100 : #cbe2ff,
  A200 : #98c6ff,
  A400 : #65aaff,
  A700 : #4c9cff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$control-comercio-plataforma-primary: mat-palette($mat-colors-primary);
$control-comercio-plataforma-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$control-comercio-plataforma-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$control-comercio-plataforma-theme: mat.define-light-theme((
  color: (
    primary: $control-comercio-plataforma-primary,
    accent: $control-comercio-plataforma-accent,
    warn: $control-comercio-plataforma-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($control-comercio-plataforma-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: "Montserrat", "Helvetica Neue", sans-serif; }
